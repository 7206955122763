import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};

const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Curso de auditores<br></br> Welfare Quality:</span> <br></br>
              Matadouro Bovinos
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal2} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Antoni Dalmau Bueno
                      </div>
                    
                    <div className="titulo inter-light">
                     Professor e consultor IRTA
                      </div>
                      </div>
                    
                  </div>
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal3} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Quim Pallisera Lloveras
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultor
                      </div>
                      </div>
                    
                  </div>
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                       28, 29 e 30 de Maio de 2021
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Inglês
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      550 € +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/WQ-matadouro-bovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfe9D2fOGiUjocU5QPsyh51wiK5cwCK244D2oZS8ZO-hLrpcw/viewform" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-matadouro-bovinos">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Masterclass
          </div>
          <div className="text inter-regular">
          A rede Welfare Quality Network está a traçar diretrizes e protocolos que garantem o bem-estar animal em bovinos com aceitação a nível Europeu pelas entidades retalhistas e compradores. Nesta ótica, se a cadeia produtiva (matadouros) pretende ultrapassar auditorias e em última instância obter selos que certificam o processo terá que implementar alterações que visam o respeito dos protocolos.
            </div>
      </div>
      <div className="wrapper-logos">
        <div className="col1">
        <img className="imagem" src={logo1} alt="" />

        </div>
        <div className="col2">
        <img className="imagem" src={logo2} alt="" />

        </div>
      </div>

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          <br></br>Assim, a dinâmica do curso possibilita uma aprendizagem intensiva, organizada e coerente dos diferentes módulos abordados.
            </div>
      </div>
      <div className="wrapper-program">
      {/* <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Dia 1 - 28/05/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body> 
                                <div class="modulo">
                                <p><span class="mod">Módulo 1</span> - <span class="txt">Apresentação de protocolos do bem estar animal</span></p>
                                </div> 
                                <div class="modulo">
                                <p><span class="mod">Módulo 2</span> - <span class="txt">Boa alimentação</span></p>
                                  </div></Card.Body>

                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                            Dia 2 - 29/05/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>This is second tab body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                            Dia 3 - 30/05/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>This is second tab body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> */}
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/WQ-matadouro-bovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">

                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfe9D2fOGiUjocU5QPsyh51wiK5cwCK244D2oZS8ZO-hLrpcw/viewform" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={speaker1} alt="" />

              </div>
              <div className="nome">
                Antoni Dalmau Bueno
              </div>
              <div className="titulo">
                  Professor e Consultor
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Coordendor técnico IRTA - Institute of Agrisearch and technology</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Species leader em Suínos da Welfare Quality Network</p>
                  </div>
              </div>
            </div>
        </div>
        </div>
        <div className="col1">  

        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={speaker1} alt="" />

              </div>
              <div className="nome">
                Quim Pallisera Lloveras
              </div>
              <div className="titulo">
                  Professor e Consultor
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Coordendor técnico IRTA - Institute of Agrisearch and technology</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Species leader em Suínos da Welfare Quality Network</p>
                  </div>
              </div>
            </div>
        </div>
       
          
          </div>
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
    </div>
  </Layout>
)

export default wqmatadouros
